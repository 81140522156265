import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {isMembersAreaInstalled, maybeInstallMembersArea} from '@wix/members-area-integration-kit';
import {doTransaction} from '../transaction';
import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {ecomAppDefID} from '../constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';

export function createAppApi(sdk, dependantApps, styleParams, experiments, t, biLogger): AppApiModel {
  return {
    isMembersInstalled: () => isMembersAreaInstalled(),
    installMembersAreaAndWishlistPage: (openerCompRef: string) => {
      return doTransaction(sdk, async () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-init'});
        const progressBarPromise = showProgressBar(sdk, t, true);

        await maybeInstallMembersArea();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-after-members-install'});
        await dependantApps.tryInstallWishlist();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-after-wishlist-install'});

        const close = await progressBarPromise;
        await delay(500);
        close();
        await delay(500);

        await enableProductPageWishlist(sdk, openerCompRef);

        sdk.editor
          .showUserActionNotification('', {
            message: t('settings.productPage.floatingNotification.membersAreaAdded'),
            type: 'success',
            link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
          })
          .then((linkClicked) => {
            linkClicked && navigateToWishlist(sdk, dependantApps, biLogger);
          });
      });
    },
    installWishlist: async (openerCompRef: string) => {
      return doTransaction(sdk, async () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installWishlist-init'});

        const progressBarPromise = showProgressBar(sdk, t, false);

        await dependantApps.tryInstallWishlist();

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installWishlist-after-wishlist-install'});

        const close = await progressBarPromise;
        await delay(500);
        close();
        await delay(500);

        await enableProductPageWishlist(sdk, openerCompRef);

        sdk.editor
          .showUserActionNotification('', {
            message: t('settings.productPage.floatingNotification.wishlistAdded'),
            type: 'success',
            link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
          })
          .then((linkClicked) => {
            linkClicked && navigateToWishlist(sdk, dependantApps, biLogger);
          });
      });
    },
    uninstallWishlist: () => {
      return doTransaction(sdk, () => dependantApps.uninstallWishlistPageInMembersArea());
    },
    isAppInstalled: (appDefinitionId: string) => {
      return dependantApps.isAppInstalled(appDefinitionId);
    },
    setCheckoutStyleParam(type: string, key: string, param: {value: any}): Promise<void> {
      return doTransaction(sdk, () => styleParams.setCheckoutStyleParam(type, key, param));
    },
    addShopPage: () => {
      return doTransaction(sdk, async () => {
        const allSitePages = await sdk.pages.data.getAll();
        const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
        const managingAppDefId = ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT)
          ? ecomAppDefID
          : STORES_APP_DEF_ID;
        return sdk.tpa.add.component('', {
          appDefinitionId: ecomAppDefID,
          managingAppDefId,
          page: {pageId: 'product_gallery'},
          componentType: 'PAGE',
        });
      });
    },
  };
}

function getAssetsUrl() {
  const version = getAppVersion();
  return `https://static.parastorage.com/services/wixstores-client-worker/${version}/assets`;
}

async function enableProductPageWishlist(sdk, productPageCompRef: string) {
  await sdk.tpa.setStyleParams('', {
    compRef: {id: productPageCompRef, type: 'DESKTOP'},
    styleParams: [{type: 'boolean', key: 'productPage_wishlistEnabled', param: {value: true}}],
  });
}

export async function navigateToWishlist(sdk, dependantApps, biLogger) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  biLogger.clickToPreviewWishlistInMembersSfe({});
  const wishlistPageRef = await dependantApps.getWishlistPageRef();
  sdk.document.pages.navigateTo('', {pageRef: wishlistPageRef});
}

const showProgressBar = (sdk, t, shouldInstallMembers: boolean = true): Promise<Function> => {
  let currStep = 1;
  const baseTranslationKey = shouldInstallMembers
    ? 'settings.productPage.loadingModal.addingMembersArea'
    : 'settings.productPage.loadingModal.addingWishlist';
  const progressBarTitle = t(baseTranslationKey);
  const progressBarSubTitles = [
    t(`${baseTranslationKey}.firstSubtitle`),
    t(`${baseTranslationKey}.secondSubtitle`),
    t(`${baseTranslationKey}.thirdSubtitle`),
  ];
  const timeToWaitBetweenSteps = shouldInstallMembers ? 6000 : 3500;

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(async (resolve) => {
    const panelRef = await sdk.editor.openProgressBar('', {
      title: progressBarTitle,
      totalSteps: 3,
      currentStep: currStep,
      stepTitle: progressBarSubTitles[0],
      image: `${getAssetsUrl()}/images/wishlist.svg`,
    });
    await delay(timeToWaitBetweenSteps);
    await sdk.editor.updateProgressBar('', {
      panelRef,
      currentStep: ++currStep,
      stepTitle: progressBarSubTitles[1],
    });
    await delay(timeToWaitBetweenSteps);
    await sdk.editor.updateProgressBar('', {
      panelRef,
      currentStep: ++currStep,
      stepTitle: progressBarSubTitles[2],
    });
    await delay(500);
    resolve(() => {
      sdk.editor.closeProgressBar('', false, {panelRef});
    });
  });
};
