import {ecomAppDefID, EDITOR_SCRIPT_DSN, Events, SPECS} from './constants';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {withMembersArea} from '@wix/members-area-integration-kit';
import {Logger, exposureEventForTestsParams} from '@wix/bi-logger-ec-sf';
import {getAppManifest} from '../common/appManifest/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {DependantApps} from './services/DependantApps';
import {StyleParams} from './services/StyleParams';
import {translateFunctionFactory} from '../common/translations/translations';
import {
  setSentryInstance,
  withErrorReportingWrapping,
  wrapAsyncFunctionsWithPromise,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {
  addMembersArea,
  addPage,
  addWidget,
  openEcomBillingPage,
  updateEcomPlatformInstallations,
  updateEcomPagesForPagesPanel,
  getEcomPlatformAppManifest,
  setStateForPages,
  setStateForStoresPages,
} from './editor-script-partials/publicApi';
import {handleOnEvent} from './editor-script-partials/onEvent';
import {doTransaction} from './transaction';
import {handleAction} from './editor-script-partials/handleAction';
import {createAppApi} from './editor-script-partials/appApi';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {EditorSDK} from '@wix/platform-editor-sdk';
import {STORES_APP_DEF_ID} from '../stores-editor-script/constants';
import {EcomPlatformInstallationState} from './services/EcomPlatformInstallationState';
import {EcomComponent} from '@wix/ecom-platform-sdk';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';

let appToken;
let options: IEditorOptions;
let t;
let locale: string = 'en';
let biLogger: Logger;
let sdk: IEditorSdk;
let experimentsApi: ExperimentsApi;
let dependantApps: DependantApps;
let appApi: AppApiModel;
let ecomPlatformInstallationState: EcomPlatformInstallationState;
let styleParams: StyleParams;
let storeId: string;

function createSentryInstance(monitoring) {
  const configuration = {
    dataCallback: (data) => {
      data.environment = 'Worker';
      return data;
    },
  };
  return monitoring.createSentryMonitorForApp(EDITOR_SCRIPT_DSN, configuration);
}

function isMembersPage(tpaPageId) {
  return tpaPageId === PageMap.ORDER_HISTORY || tpaPageId === PageMap.WISHLIST;
}

async function addStoresPagesAsPanel() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  return Promise.all(
    storesPages.map(async (page) => {
      const pageRef = {id: page.id, type: page.type};
      const pageData = await sdk.pages.data.get('', {
        pageRef,
      });
      if (!pageData.managingAppDefId && !isMembersPage(pageData.tpaPageId)) {
        await sdk.pages.data.update('', {
          pageRef,
          data: {managingAppDefId: ecomAppDefID},
        });
        return pageData;
      }
    })
  );
}

async function setStateForAppPages() {
  const applicationPages = await sdk.document.pages.getApplicationPages('');
  applicationPages
    .filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID)
    .forEach((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CART ||
        pageData.tpaPageId === PageMap.THANKYOU
      ) {
        sdk.document.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    });
}

async function setStoresPages() {
  const shouldUseLightboxes = experimentsApi.enabled(SPECS.UseLightboxes);
  await setStateForAppPages();
  await dependantApps.addCheckoutIfNeeded();
  shouldUseLightboxes && (await dependantApps.addLighboxes(options.initialAppData.applicationId));
}

function registerEvents() {
  sdk.document.application.registerToCustomEvents('', {eventTypes: [Events.dashboardClosed]});
}

async function isProductPageOnEcom() {
  if (!experimentsApi.enabled(SPECS.ShowPagesPaenlTitleByEcomPages)) {
    return true;
  }
  const allSitePages = await sdk.pages.data.getAll();
  const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
  return ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT);
}

async function editorReady(_editorSDK, _appToken, _options) {
  options = _options;
  appToken = _appToken;
  sdk = _editorSDK;

  const instance: string = await (sdk as any).document.info.getAppInstance('');
  experimentsApi = new ExperimentsApi(instance);
  await experimentsApi.fetch();

  setSentryInstance(createSentryInstance(options.monitoring));
  wrapAsyncFunctionsWithPromise(true);
  registerEvents();

  const encodedInstance = instance.substring(instance.indexOf('.') + 1);
  const parsedInstance = JSON.parse(atob(encodedInstance));
  storeId = parsedInstance.instanceId;
  const isMerchant = true;
  biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
    storeId,
    isMerchant,
    appName: 'wixstores worker',
  });

  dependantApps = new DependantApps(sdk, biLogger, instance, options.initialAppData.metaSiteId);
  ecomPlatformInstallationState = new EcomPlatformInstallationState();
  styleParams = new StyleParams(sdk, options.initialAppData.applicationId);
  locale = (await sdk.editor.environment.getLocale()) || locale;
  t = await translateFunctionFactory(locale);
  await doTransaction(sdk, async () => {
    if (!experimentsApi.enabled(SPECS.EcomPlatformInstallation)) {
      await addMembersArea(options.origin.type, options.firstInstall, options.biData);
    }
    await dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded({biData: options.biData});

    if (!experimentsApi.enabled(SPECS.EcomPlatformInstallation)) {
      const storesPages = await addStoresPagesAsPanel();
      if (storesPages.length) {
        await setStoresPages();
      }
    }

    const biData: exposureEventForTestsParams = {
      testName: SPECS.InstallNewStoresUponStoresInstallation,
      type: options.origin.type,
    };
    if (experimentsApi.enabled('specs.stores.ReportMissingApp') && !options.firstInstall) {
      const newStoresInstalled = await sdk.document.tpa.app.getDataByAppDefId('', STORES_APP_DEF_ID);
      biData.is_eligible = !!newStoresInstalled;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    biLogger.exposureEventForTests(biData);
    if (
      !experimentsApi.enabled(SPECS.EcomPlatformInstallation) &&
      experimentsApi.enabled(SPECS.InstallNewStoresUponStoresInstallation)
    ) {
      await dependantApps.installNewStoresAppIfNeeded();
    }
  });
  appApi = createAppApi(sdk, dependantApps, styleParams, experimentsApi.experiments, t, biLogger);
  sdk.editor.setAppAPI('', withErrorReportingWrapping(appApi));
}

const publicApi = {
  addPage: (pageId: string, managingAppDefId = ecomAppDefID, shouldNavigate = false) =>
    addPage(sdk, pageId, managingAppDefId, shouldNavigate),
  addWidget: (widgetId: string, addToAllPages: boolean) => addWidget(sdk, widgetId, addToAllPages),
  addMembersArea: (editorType: string, firstInstall: boolean, biData?: {origin?: string}) =>
    addMembersArea(editorType, firstInstall, biData),
  setStateForStoresPages: () => setStateForStoresPages(sdk),
  setStateForPages: () => setStateForPages(sdk),
  openEcomBillingPage: () => openEcomBillingPage(sdk),
  updateEcomPlatformInstallations: (appDefId: string, installedComponents: EcomComponent[]) =>
    updateEcomPlatformInstallations(ecomPlatformInstallationState, appDefId, installedComponents),
  updateEcomPagesForPagesPanel: () => updateEcomPagesForPagesPanel(sdk, ecomPlatformInstallationState),
  getEcomPlatformAppManifest: () => getEcomPlatformAppManifest(t, locale),
};

export const editorScript = withMembersArea(
  withErrorReportingWrapping({
    editorReady,
    handleAction: (data) => handleAction(data, {sdk, storeId, dependantApps, ecomPlatformInstallationState, biLogger}),
    getAppManifest: async ({appManifestBuilder}: {appManifestBuilder: AppManifestBuilder}, editorSDK: EditorSDK) =>
      getAppManifest({
        t,
        locale,
        token: appToken,
        hasStoresPremium: experimentsApi.hasStoresPremium,
        exps: experimentsApi.experiments,
        appManifestBuilder,
        editorSDK,
        options,
        pagesPanelTitle: (await isProductPageOnEcom())
          ? t('Pages_Panel_storesPages')
          : t('Pages_Panel_ecomPlatformPages'),
      }),
    onEvent: (data) => handleOnEvent(data, sdk, dependantApps, appApi),
    getControllerPresets: () => Promise.resolve([]),
    exports: publicApi,
  }),
  {installAutomatically: false}
);
