import {Events} from '../constants';
import {DependantApps} from '../services/DependantApps';
import {doTransaction} from '../transaction';
import {handleAppsManagerActions} from '../../common/appManifest/appManifest';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';

export async function handleOnEvent(
  {eventType, eventPayload}: {eventType: Events; eventPayload: any},
  sdk: IEditorSdk,
  dependantApps: DependantApps,
  appApi: AppApiModel
) {
  switch (eventType) {
    case Events.manageStores:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Events.deletePage: {
      const {pageRef} = eventPayload;
      return doTransaction(sdk, () => sdk.pages.remove('', {pageRef}));
    }
    case Events.addShopPage:
      return appApi.addShopPage();
    case Events.dashboardClosed:
      return doTransaction(sdk, () => dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded());
    case Events.appActionClicked:
      return handleAppsManagerActions(sdk, eventPayload.actionId);
  }
}
